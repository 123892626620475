import { MToolboxPermit } from './toolbox-permit.model';
import { Toolbox } from '../_interfaces/Organization';

export class MToolbox{
    id: number;
    name: string;
    url: string;
    toolboxPermits: MToolboxPermit[];

    constructor (toolbox: Toolbox){
        this.id = toolbox.id;
        this.name = toolbox.nombre;
        this.url = toolbox.url;
        this.toolboxPermits = []
        for (let i = 0; i < toolbox.permiso_toolboxes.length; i++) {
            this.toolboxPermits.push(new MToolboxPermit(toolbox.permiso_toolboxes[i]))
        }
    }
}