import { ToolboxPermit } from '../_interfaces/Organization';

export class MToolboxPermit {
    id: number;
    name: string;
    keyName:string

    constructor(permit: ToolboxPermit){
        this.id = permit.id,
        this.name = permit.nombre,
        this.keyName = permit.nombre_clave
    }
}