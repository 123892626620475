import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.sass']
})
export class LogoutComponent implements OnInit {

  constructor() { }

  @Output() public close = new EventEmitter<string>();
  @Output() public dismiss = new EventEmitter<string>();

  public onClose(message:string){
    this.close.emit(message)
  }

  public onDismiss(message:string){
    this.dismiss.emit(message)
  }

  ngOnInit() { }

}
