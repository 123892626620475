import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "auth", loadChildren: "../auth/auth.module#AuthModule" },
  { path: "home", loadChildren: "../home/home.module#HomeModule" },
  { path: "profile", loadChildren: "../profile/profile.module#ProfileModule" },
  {
    path: "security",
    loadChildren: "../security/security.module#SecurityModule"
  },
  {
    path: "organization",
    loadChildren: "../organization/organization.module#OrganizationModule"
  },
  { path: "process", loadChildren: "../process/process.module#ProcessModule" },
  { path: "**", redirectTo: "home" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
