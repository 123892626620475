import { MCountry } from './country.model';
import { MOrganizationType } from './organization-type.model';
import { MToolbox } from './toolbox.model';
import { Organization } from '../_interfaces/Organization';
import { MLanguage } from './language.model';

export class MOrganization{
    public email: string;
    public address: string;
    public id: number;
    public identification: string;
    public code: string;
    public logo: string;
    public maxUsersH: string;
    public maxUsersS: string;
    public name: string;
    public country: MCountry;
    public organizationType: MOrganizationType;
    public toolboxes: MToolbox[];
    public language: MLanguage;

    constructor(organization: Organization){
        this.email = organization.correo;
        this.address = organization.direccion;
        this.id = organization.id;
        this.identification = organization.identificacion;
        this.code = organization.codigo;
        this.logo = organization.logo;
        this.maxUsersH = organization.maximo_usuarios_h;
        this.maxUsersS = organization.maximo_usuarios_s;
        this.name = organization.nombre;
        this.country = new MCountry(organization.pais);
        this.organizationType = new MOrganizationType(organization.tipo_organizacion);
        this.toolboxes = [];
        this.language = new MLanguage(organization.idioma)
        for (let i = 0; i < organization.toolboxes.length; i++) {
            this.toolboxes.push(new MToolbox(organization.toolboxes[i]))
        }
    }
}