import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MMenuItem } from 'src/app/_models/menu-item.model';
import { MenuItem } from 'src/app/_interfaces/User';

@Component({
  selector: 'itemMenu',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.sass']
})
export class MenuItemComponent implements OnInit, OnChanges {

  @Input('item') public item: MMenuItem;
  @Input() public closeMenu: boolean;
  @Input() public isChild: boolean = false;
  @Input() public generation: number = 0;
  @Output() public closeAll = new EventEmitter<boolean>()
  @Output() public closeCollapse = new EventEmitter<boolean>()
  @ViewChild('subMenu') public submenu: ElementRef;
  public childReference: boolean = false;
  public opened:boolean = false;
  public height:number = 0;
  public recent:boolean = false;

  constructor() {
  }

  ngOnInit() { }

  public changeState(){
    if (this.opened){
      this.collapse()
    } else {
      this.expand()
    }
    // console.log(this.opened)
    this.closeAll.emit(true)
  }

  public expand(){
    if (!this.recent) {
      this.recent = true;
      this.opened = true;
      this.setRecent()
    }
  }

  public collapse(){
    if (!this.recent) {
      this.recent = true;
      this.opened = false;
      this.setRecent()
      this.childReference = !this.childReference
    }
  }

  public setRecent(){
    this.recent = true;
    setTimeout(()=>{
      this.recent = false;
    },10)
  }

  public checkUrl(url: string){
    if (url.substr(0,1) === '/') {
      return url
    } else {
      // console.log('/'+url)
      return '/' + url
    }
  }

  public closeChildren(){
    this.closeCollapse.emit(true)
  }

  ngOnChanges() {
    if (!this.recent) {
      this.collapse()
    }
  }

}
