import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { SieniService } from '../_services/sieni.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MUser } from '../_models/user.model';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title = 'sieni';
  public isCollapsed: boolean = true;
  public usuario:MUser = null;
  public width:number = 0;
  public collapse: boolean = true;
  public closeMenus: boolean = true;
  public isDropOpen: boolean = false;
  public dropdownControl: boolean = true;
  public hover:boolean = false;
  public imgSrc:string = null;
  public imgUrl:string = null;
  public environment: SafeResourceUrl;

  constructor(private _globalService:SieniService, private router:Router,
    private modalService: NgbModal, private translate: TranslateService) { }

  ngOnInit(){
    this.environment =  environment.imgUrl;
    this._globalService.getSettings('site','logo').subscribe((res : any)=> this.imgUrl =  environment.imgUrl + '/storage/' + res.data);
    this.translate.setDefaultLang("es")
    if (this._globalService.isLoggedIn()){
      this.usuario = this._globalService.user;
      this.translate.use(this.usuario.language.code)
      this.imgSrc = environment.imgUrl + '/storage/' + this.usuario.avatar;
    }
    // console.log(this.usuario)
  }

  public dropdown(){
    if (this.dropdownControl) {
      this.dropdownControl = false;
      this.isDropOpen = !this.isDropOpen
      setTimeout(()=>{
        this.dropdownControl = true;
      }, 5)
    }
  }

  public setUser(newUser:MUser){
    this.usuario = newUser;
    this.translate.use(this.usuario.language.code)
    this.updateImg();
    // console.log(this.imgSrc)
  }

  public closeAllMenus(){
    if (this.collapse){
      this.closeMenus = !this.closeMenus
    }
  }

  public closeChildren(){
    // console.log(this.width)
    if (this.width < 768) {
      this.setCollapse()
    }
  }

  public setCollapse(){
    this.collapse = !this.collapse
  }

  public logOut(){
    this.isDropOpen = false;
    this._globalService.logOut();
    this.usuario = null;
    this.router.navigate(['/auth','login'])
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
      // console.log(result)
      this.logOut()
    }, (reason) => {
      // console.log(reason)
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public updateImg(){
    this.imgSrc = this.environment + '/storage/' + this.usuario.avatar + "?updatedAt=" + Date.now()
  }

}
