import { Country } from '../_interfaces/Organization';

export class MCountry{
    code: string;
    id: number;
    language: string;
    name: string

    constructor(country: Country){
        this.code = country.codigo;
        this.id = country.id;
        this.language = country.idioma;
        this.name = country.nombre
    }
}