import { Language } from '../_interfaces/User';

export class MLanguage{
    public id: number = null;
    public code: string = null;
    public name: string = null;

    constructor(lang: Language){
        if (lang) {
            this.id = lang ? lang.id : 1;
            this.code = lang ? lang.codigo.toLowerCase() : 'es'
            this.name = lang ? lang.nombre : 'Español';
        }
    }
}