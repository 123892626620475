import { MenuItem } from '../_interfaces/User';

export class MMenuItem {
    id: number;
    menuId: string;
    title: string;
    url: string;
    target: string;
    iconClass: string;
    color: string;
    parentId: string;
    order: string;
    parameters: string;
    children: MMenuItem[] = [];

    constructor(menuItem: MenuItem) {
        this.id = menuItem.id;
        this.menuId = menuItem.menu_id;
        this.title = menuItem.title;
        this.url = menuItem.url;
        this.target = menuItem.target;
        this.iconClass = menuItem.icon_class;
        this.color = menuItem.color;
        this.parentId = menuItem.parent_id;
        this.order = menuItem.order;
        this.parameters = menuItem.parameters
        // this.children = [];
        if (menuItem.children && menuItem.children.length > 0) {
            for (let i = 0; i < menuItem.children.length; i++) {
                this.children.push(new MMenuItem(menuItem.children[i]))
            }
        }
    }

}
