import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LogoutComponent],
  imports: [
    CommonModule,
    AngularFontAwesomeModule,
    TranslateModule
  ],
  exports: [LogoutComponent]
})
export class LogoutModule { }
