import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MUser } from '../_models/user.model';
import { SecurityQuestion } from '../_interfaces/User';
import { OrganizationUser, OrganizationUserMassive, Process, Organization } from '../_interfaces/Organization';
import { MOrganization } from '../_models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class SieniService {

  constructor(
    private httpClient: HttpClient
    ) { }

  public user:MUser = null;
  public org:MOrganization = null;
  private pass: string = null;

  private header(): HttpHeaders{
    return new HttpHeaders({
      "Authorization": "Bearer "+ this.user.token,
      "Accept":"application/json",
      "Content-Type":"application/json"
    })
  }

  /**
   * @author Carlos Albornoz
   * @name setUser
   * @description Guaradar un usuario y que este disponible para toda la aplicacion
   * @param newUser Hace referencia al nuevo usuario que se va a guardar
   */
  public setUser(newUser:MUser){
    this.user = newUser;
    if (this.user.remember) {
      localStorage.setItem("activeUser", JSON.stringify(this.user))
    } else {
      sessionStorage.setItem("activeUser", JSON.stringify(this.user))
    }
  }

  /**
  * @author Carlos Albornoz
  * @name setOrg
  * @description Guarda una organizacion para que este disponible para toda la aplicacion
  * @param newOrg Hace referencia a la nueva organizacion a guardar
  */
  public setOrg(newOrg:MOrganization, rawData: Organization = null){
    this.org = newOrg;
    sessionStorage.setItem("organization", JSON.stringify(this.org))
    if (rawData) {
      sessionStorage.setItem("rawOrganizationData", JSON.stringify(rawData))
    }
  }

  public async getCurrentOrganization(){
    if (this.org) {
      return await this.org;
    } else if (sessionStorage.getItem("organization")) {
      this.org = JSON.parse(sessionStorage.getItem("organization"))
      return await this.org
    } else {
      let org;
      const value = await this.getOrganization().toPromise().then(async ({data}:any)=>{
        org = new MOrganization(data);
        this.setOrg(org, data)
      });
      return await org;
    }

  }

  /**
   * @author Carlos Albornoz
   * @name setOrganizationLogo
   * @description Permite cambiar el logo de una organizacion
   */
  public setOrganizationLogo(image: File, base64: string){
    let body = {
      filename: image.name,
      base64: base64
    }
    return this.httpClient.post(environment.apiUrl + "/api/organizacion/"+ this.org.id +"/logo",
    body, {headers:this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name setOrganizationLanguage
   * @description Permite cambiar el idioma de una organizacion
   */
  public setOrganizationLanguage(langId: string){
    let body = {
      idioma_id: langId
    }
    return this.httpClient.put(environment.apiUrl + "/api/organizacion/" + this.org.id,
    body, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name isValid
   * @description Devuelve la validez de un usuario
   * @returns boolean
   */
  public isValid(){
    if (!this.user) {
      if (!localStorage.getItem("activeUser")) {
        return false;
      }
      let activeUser:MUser = JSON.parse(localStorage.getItem("activeUser"));
      this.setUser(activeUser);
      if (!this.user.valid) {
        return false
      }
    } else if (!this.user.valid) {
      return false
    }
    return true;
  }

  /**
   * @author Carlos Albornoz
   * @name isLoggedIn
   * @description Verifica si hay un usuario activo
   */
  public isLoggedIn(){
    if (!this.user) {
      if (!localStorage.getItem("activeUser")) {
        if (!sessionStorage.getItem("activeUser")) {
          return false
        } else {
          let activeUser:MUser = JSON.parse(sessionStorage.getItem("activeUser"));
          this.setUser(activeUser);
        }
      } else {
        let activeUser:MUser = JSON.parse(localStorage.getItem("activeUser"));
        this.setUser(activeUser);
      }
    }
    return true;
  }

  /**
   * @author Carlos Albornoz
   * @name logIn
   * @description Permite al usuario iniciar sesión en el sistema
   * @param user Nombre de Usuario
   * @param pass Contraseña del Usuario
   * @param remember Si el usuario desea ser recordado o no
   */
  public logIn(user:string, pass:string, remember:boolean = false){
    let header = new HttpHeaders({
      "Content-Type":"application/json",
      "Accept":"application/json",
    })
    let body = JSON.stringify({
      'username': user,
      'password': pass,
      'remember_me': remember
    })
    return this.httpClient.post(environment.apiUrl + "/api/auth/login", body, {headers:header})
  }

  /**
   * @author Carlos Albornoz
   * @name logOut
   * @description Metodo para cerrar la sesion
   */
  public logOut(){
    this.user = null;
    this.org = null;
    this.pass = null;
    localStorage.clear()
    sessionStorage.clear()
  }

  public setPass(pass:string){
    this.pass = pass
  }

  public getPass(){
    return this.pass
  }

  /**
   * @author Carlos Albornoz
   * @name getUser
   * @description Una vez iniciada la sesión, se utiliza para obtener la informacion del usuario
   * @param token Token del Usuario
   */
  public getUser(token:string){
    let header = new HttpHeaders({
      "Authorization": "Bearer "+token,
      "Accept":"application/json",
      "Content-Type":"application/json",
    })
    return this.httpClient.get(environment.apiUrl + "/api/auth/user", {headers:header})
  }

  /**
   * @author Carlos Albornoz
   * @name getMenuItems
   * @description Obtiene las opciones de menu del usuario
   * @param token El token del usuario al ingresar
   */
  public getMenuItems(token:string){
    let header = new HttpHeaders({
      "Authorization": "Bearer "+token,
      "Accept":"application/json",
      "Content-Type":"application/json",
    })
    return this.httpClient.get(environment.apiUrl + "/api/profile/menu", {headers:header})
  }

  /**
   * @author Carlos Albornoz
   * @name getQuestions
   * @description Obtiene las preguntas de seguridad del sistema
   */
  public getQuestions(n){
    return this.httpClient.get(environment.apiUrl + "/api/profile/question-list/"+n, {headers:this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getUserQuestions
   * @description Obtiene las preguntas de seguridad del usuario
   */
  public getUserQuestions(){
    return this.httpClient.get(environment.apiUrl + "/api/profile/questions", {headers:this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name setUserQuestions
   * @description Obtiene las preguntas de seguridad del usuario
   */
  public setUserQuestions(questions: SecurityQuestion[]){
    let body = JSON.stringify({
      opciones: questions
    })
    return this.httpClient.put(environment.apiUrl + "/api/profile/questions", body, {headers:this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name setProfileInfo
   * @description Actualiza la informacion de un usuario
   * @param newName El nuevo nombre del usuario
   * @param newEmail El nuevo email del usuario
   */
  public setProfileInfo(newName:string, newEmail:string, langId: number, newAvatar:string = null){
    let data
    if (newAvatar) {
      data = {
        name: newName,
        email: newEmail,
        idioma_id: langId,
        avatar: newAvatar
      }
    } else {
      data = {
        name: newName,
        idioma_id: langId,
        email: newEmail
      }
    }
    let body = JSON.stringify(data)
    return this.httpClient.put(environment.apiUrl + "/api/profile/edit", body, {headers:this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name setPassword
   * @description Peticion para cambiar la contraseña de un usuario
   */
  public setPassword(current:string, newpass:string, confirm:string){
    let body = JSON.stringify({
      current_password: current,
      password: newpass,
      password_confirmation: confirm
    });
    return this.httpClient.put(environment.apiUrl + "/api/profile/password", body, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name resetPassword
   * @param user Nombre de cuenta del usuario
   * @param pass La nueva contraseña a utilizar
   * @param confirm La confirmacion de la contraseña
   * @param token Token de Validacion
   */
  public resetPassword(user:string, pass:string, confirm: string, token:string){
    let body = JSON.stringify({
      username: user,
      password: pass,
      password_confirmation: confirm,
      token: token
    })
    let header = new HttpHeaders({
      "Accept":"application/json",
      "Content-Type":"application/json",
    })
    return this.httpClient.post(environment.apiUrl + "/api/auth/password/reset", body, {headers: header})
  }

  /**
   * @author Carlos Albornoz
   * @name getValidationQuestions
   * @description Obtiene 2 preguntas de un usuario con el fin de validarlas
   * @param username Nombre de usuario de quien se buscaran las preguntas
   */
  public getUserUsername(email: string){
    let body = JSON.stringify({
      forgot_all: true,
      email: email
    })
    let header = new HttpHeaders({
      "Accept":"application/json",
      "Content-Type":"application/json"
    })
    return this.httpClient.post(environment.apiUrl + '/api/auth/password/account', body, {headers: header})
  }

  /**
   * @author Carlos Albornoz
   * @name getValidationQuestions
   * @description Obtiene 2 preguntas de un usuario con el fin de validarlas
   * @param username Nombre de usuario de quien se buscaran las preguntas
   */
  public getValidationQuestions(username: string){
    let body = JSON.stringify({
      username: username
    })
    let header = new HttpHeaders({
      "Accept":"application/json",
      "Content-Type":"application/json"
    })
    return this.httpClient.post(environment.apiUrl + '/api/auth/password/account', body, {headers: header})
  }

  /**
   * @author Carlos Albornoz
   * @name validateQuestions
   * @description Valido 2 preguntas para obtener un token
   * @param questions Mandar 2 preguntas con sus respectivas respuestas
   */
  public validateQuestions(username:string ,questions:SecurityQuestion[]){
    let body = JSON.stringify({
      username: username,
      opciones: questions
    })
    let header = new HttpHeaders({
      "Accept":"application/json",
      "Content-Type":"application/json"
    })
    return this.httpClient.post(environment.apiUrl + "/api/auth/valid-questions", body, {headers: header})
  }

  /**
   * @author Carlos Albornoz
   * @name getOrganizationUser
   * @description Retorna la empresa a la que esta afiliado el usuario
   */
  public getOrganization(){
    return this.httpClient.get(environment.apiUrl + "/api/organizacion", {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getAllOrganizationUsers
   * @description Retorna los usuarios de la organizacion al que el usuario esta afiliado
   */
  public getAllOrganizationUsers(){
    return this.httpClient.get(environment.apiUrl + "/api/organizacion/usuario", {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getOrganizationUser
   * @description Retorna un usuario afiliado a la organizacion a la que el usuario actual esta afiliado
   */
  public getOrganizationUser(){
    return this.httpClient.get(environment.apiUrl + "/api/organizacion/usuario/" + this.user.id, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name createOrganizationUser
   * @description Crea un usuario para la organizacion a la que el usuario actual esta afiliado
   */
  public createOrganizationUser(user: OrganizationUser){
    let body = JSON.stringify(user)
    return this.httpClient.post(environment.apiUrl + "/api/organizacion/usuario", body, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name updateOrganizationUser
   * @description Actualiza a un usuario afiliado a la organizacion a la que el usuario actual esta afiliado
   */
  public updateOrganizationUser(user: OrganizationUser){
    let body = JSON.stringify(user)
    return this.httpClient.put(environment.apiUrl + "/api/organizacion/usuario", body, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getOrganizationUserById
   * @description Retorna un usuario de una organizacion en base a su id
   */
  public getOrganizationUserById(id: number, token?: string){
    if (this.user) {
      return this.httpClient.get(environment.apiUrl + "/api/organizacion/usuario/"+id, {headers: this.header()})
    } else {
      let header = new HttpHeaders({
        "Authorization": "Bearer "+ token,
        "Accept":"application/json",
        "Content-Type":"application/json",
      })
      return this.httpClient.get(environment.apiUrl + "/api/organizacion/usuario/"+id, {headers: header})
    }
  }

  /**
   * @author Carlos Albornoz
   * @name deleteOrganizationUser
   * @description Elimina un usuario afiliado a la organizacion a la que el usuario actual esta afiliado
   */
  public deleteOrganizationUser(userId: string){
    return this.httpClient.delete(environment.apiUrl + "/api/organizacion/usuario/" + userId, {headers: this.header()})
  }


  /**
   * @author Carlos Albornoz
   * @name updateUsersMassively
   * @param users El id de los usuarios a actualizar junto con los permisos que se les asignaran
   * @description Actualiza los permisos de los usuarios en bulto
   */
  public updateUsersMassively(users: OrganizationUserMassive[]){
    let data = {
      usuarios: users
    }
    let body = JSON.stringify(data)
    return this.httpClient.put(environment.apiUrl + "/api/organizacion/usuario/permiso_masivo", body, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getProcesses
   * @description Retorna los procesos de a los que tiene acceso el usuario
   */
  public getProcesses(){
    return this.httpClient.get(environment.apiUrl + "/api/proceso", {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getProcessById
   * @description Retorna un proceso segun el id
   */
  public getProcessById(id: number){
    return this.httpClient.get(environment.apiUrl + "/api/proceso/" + id, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name createProcess
   * @param name Nombre del proceso a crear
   * @description Crea un nuevo proceso
   */
  public createProcess(name: string){
    let body = JSON.stringify({
      nombre: name
    })
    return this.httpClient.post(environment.apiUrl + "/api/proceso", body, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name updateProcess
   * @param process Interfaz del proceso a actualziar
   * @description Actualiza la informacion de un proceso
   */
  public updateProcess(process: Process){
    let body = JSON.stringify({
      nombre: process.nombre,
      id: process.id
    })
    return this.httpClient.put(environment.apiUrl + "/api/proceso", body, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name deleteProcess
   * @description Elimina un proceso en funcion de su id
   */
  public deleteProcess(id: number){
    return this.httpClient.delete(environment.apiUrl + "/api/proceso/" + id, {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getProcessHistory
   * @description Retorna el historial de carga de procesos
   */
  public getProcessHistory(){
    return this.httpClient.get(environment.apiUrl + "/api/proceso/historial", {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getToolboxes
   * @description Obtienes un listado de los toolboxes del sistema
   */
  public getToolboxes(){
    return this.httpClient.get(environment.apiUrl + "/api/organizacion/toolboxes", {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getModules
   * @description Obtiene un listado de los modulos activos de una organizacion
   */
  public getModules(){
    return this.httpClient.get(environment.apiUrl + "/api/organizacion/modulos", {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name importProcesses
   * @description Importa un archivo de excel para cargar los procesos de una version de modulo
   */
  public importProcesses(id: number, file: File){
    const header = new HttpHeaders({
      "Authorization": "Bearer "+ this.user.token,
      "Accept":"application/json",
      // "Content-Type":"multipart/form-data"
    })
    let body = new FormData()
    body.append("file", file, file.name)
    return this.httpClient.post(environment.apiUrl + "/api/proceso/version/" + id + "/import", body, {headers: header})
  }

  /**
   * @author Carlos Albornoz
   * @name exportProcesses
   * @description Exporta la informacion los procesos de una version de modulo
   */
  public exportProcesses(id: number){
    return this.httpClient.get(environment.apiUrl + "/api/proceso/version/" + id + "/export", {headers: this.header()})
  }

  /**
  * @author Carlos Albornoz
  * @name getLanguages
  * @description Retorna el listado de idiomas del backend
  */
  public getLanguages(){
    return this.httpClient.get(environment.apiUrl + "/api/idioma", {headers: this.header()})
  }

  /**
   * @author Carlos Albornoz
   * @name getHelp
   * @description Retorna la respuesta de ayuda segun la ruta
   */
  public getHelp(url: string){
    return this.httpClient.post(`${environment.apiUrl}/api/ayuda-sistema/search/0`,{
      url: url
    },{headers: this.header()})
  }

  public getSettings(parent: string, key: string) {
    return this.httpClient.get(`${environment.apiUrl}/api/settings/${parent}/${key}`);
  }
}
