const local = {
    apiUrl: 'https://admin.test',
    imgUrl: 'https://admin.test',
}
const dev = {
    apiUrl: 'https://dev.admin.goalempresas.com',
    imgUrl: 'https://dev.admin.goalempresas.com',
}
const prod = {
    apiUrl: 'https://admin.goalempresas.com',
    imgUrl: 'https://admin.goalempresas.com',
}

/**
 * params local = http://localhost:8000
 * params dev = https://dev.admin.goalempresas.com
 * params prod = https://admin.goalempresas.com
 */
export const production = prod;
export const env = dev;
