import { OrganizationType } from '../_interfaces/Organization';

export class MOrganizationType{
    description: string;
    id: number;

    constructor(type: OrganizationType){
        this.description = type.descripcion;
        this.id = type.id
    }
}